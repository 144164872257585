import {
  EXPERIMENT_PAGE_TEST,
  EXPERIMENT_PAGE_TEST_ORIGINAL,
  EXPERIMENT_PAGE_TEST_VARIATION,
} from '../constants/experiments';

/**
 * A map of A/B tests that we have code built around
 *
 * You can read more about what these values mean and how this file's organized
 * at https://github.com/generationtux/gentux-ecomm/blob/master/docs/experiments.md.
 */

/**
 * Below should be the following format (be sure to prepend NEXT_PUBLIC to .env variable names):
 *   
 * [EXPERIMENT_FIT_PAGE]: {
    id: process.env.NEXT_PUBLIC_OPTIMIZELY_FIT_PAGE_TEST ?? '',
    variations: {
      [EXPERIMENT_FIT_PAGE_ORIGINAL]: process.env.NEXT_PUBLIC_OPTIMIZELY_FIT_PAGE_TEST_ORIGINAL ?? '',
      [EXPERIMENT_FIT_PAGE_LANGUAGE_CHANGE]: process.env.NEXT_PUBLIC_OPTIMIZELY_FIT_PAGE_TEST_LANGUAGE_CHANGE ?? '',
    },
  }
 */
export const experiments = {
  [EXPERIMENT_PAGE_TEST]: {
    id: process.env.NEXT_PUBLIC_OPTIMIZELY_GENTUX_PAGE_TEST ?? '',
    variations: {
      [EXPERIMENT_PAGE_TEST_ORIGINAL]: process.env.NEXT_PUBLIC_OPTIMIZELY_GENTUX_PAGE_TEST_ORIGINAL ?? '',
      [EXPERIMENT_PAGE_TEST_VARIATION]: process.env.NEXT_PUBLIC_OPTIMIZELY_GENTUX_PAGE_TEST_VARIATION ?? '',
    },
  },
} as const;

export type ExperimentName = keyof typeof experiments;

export type ExperimentToBuckets = {
  -readonly [K in ExperimentName]: VariationsOf<K> | null;
};

export type VariationsOf<K extends ExperimentName> = keyof typeof experiments[K]['variations'];
