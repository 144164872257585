const loadScript = (src: string) => {
  try {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;

      const el = document.getElementsByTagName('script')[0];
      el.parentNode!.insertBefore(script, el);

      script.addEventListener('load', () => {
        resolve(script);
      });

      script.addEventListener('error', () => {
        reject(console.error(`${src} failed to load.`)); // eslint-disable-line
      });
    });
  } catch (e) {
    console.error('error loading pixels', e);
  }
};

const loader = (src: string, global: string = '') =>
  new Promise(async (resolve, reject) => {
    try {
      await loadScript(src);
      resolve(window[global as any]);
    } catch (e) {
      reject(e);
    }
  });

const page = {
  loaded: () => {
    try {
      window.ttd_dom_ready(function () {
        if (typeof window.TTDUniversalPixelApi === 'function') {
          var universalPixelApi = new window.TTDUniversalPixelApi();
          universalPixelApi.init('i9ejtsv', ['6k59jxe'], 'https://insight.adsrvr.org/track/up');
        }
      });
    } catch (e) {
      console.error('error loading pixels', e);
    }
  },
};

export class pixels {
  public page?: typeof page;

  constructor() {
    /** @ts-ignore */
    if (!pixels.instance) {
      this.page = page;
    }
  }

  async init() {
    /**
     * Begin Trade Desk Pixel
     * requested by Media Smith
     */
    try {
      await loader('https://js.adsrvr.org/up_loader.1.1.0.js');
      this.page!.loaded();
      /**
       * End Trade Desk Pixel
       */
    } catch (e) {
      console.error('error loading pixels', e);
    }
  }
}

// Create singleton
const instance = new pixels();

// Freeze object to not allow
// instance changes accross app
Object.freeze(instance);

export default instance;
