import { Customer, GtUser } from '../types';
import { loggedIn } from './metrics';

const auth = {
  signIn(user: GtUser | Customer) {
    this.storeUser(user);
    loggedIn(user);
  },

  signOut() {
    window.location.href = '/account/logout';
  },

  signedIn() {
    return window.gt.user !== null;
  },

  signedOut() {
    return window.gt.user === null;
  },

  user() {
    return window.gt.user;
  },

  userId() {
    return window.gt.user ? window.gt.user.id : null;
  },

  storeUser(user: GtUser | Customer) {
    window.gt.user = {
      id: user.id!,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email!,
      phone: user.phone!,
      primaryEventId: user.primaryEventId!,
      smsOptIn: user.smsOptIn!,
      emailOptIn: user.emailOptIn,
      state: user.state,
      isCx: user.isCx,
    };
    this.storeJwt(user.jwt);
  },

  storeUserJson(response: GtUser | Customer) {
    window.gt.user = {
      id: response.id!,
      firstName: response.first_name,
      lastName: response.last_name,
      email: response.email!,
      phone: response.phone!,
      primaryEventId: response.primary_event_id!,
      smsOptIn: response.sms_opt_in!,
      emailOptIn: response.email_opt_in,
      state: response.state,
      isCx: response.is_cx,
    };
    this.storeJwt(response.jwt);
  },

  storeJwt(jwt?: string) {
    if (jwt) {
      window.localStorage.setItem('jwt', jwt);
    }
  },
};

export default auth;
