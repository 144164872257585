import React from 'react';

interface IconQuestionMarkProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconQuestionMarkProps>;

const IconQuestionMark: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>?</title>
      <path
        d="M6.5 5.9993C6.5 3.49901 10 3.49885 10 5.9993C10 7.5 8 7.5 8 10"
        className="stroke-current"
        fill="none"
        strokeWidth="1.5"
      />
      <circle cx="8" cy="12" r="1" className="fill-current" />
    </svg>
  );
};

export default IconQuestionMark;
