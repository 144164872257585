import { PropsWithChildren } from 'react';
import { Disclosure } from '@headlessui/react';
import FooterComponent from '../partials/nav/Footer';
import HeadComponent, { HeadProps } from './Head';
import Link from 'next/link';
import IconAlert from '../components/IconAlert';
import IconArrowRight from '../components/IconArrowRight';
import AnimateHeight from '../components/AnimateHeight';
import IconTriangleDown from '../components/IconTriangleDown';
import NavMain from 'partials/nav/NavMain';
import NavSub from 'partials/nav/NavSub';

interface Props extends HeadProps {
  pageProps: any;
}

const DefaultLayout = ({ children, seo, pathname, canonicalUrl, schema, pageProps }: PropsWithChildren<Props>) => {
  const { navigation, callout, footer } = pageProps;

  const renderNav = !pathname.includes('/app/') && !pathname.includes('/checkout') && navigation;

  return (
    <>
      <HeadComponent seo={seo} schema={schema} pathname={pathname} canonicalUrl={canonicalUrl} />
      <div className="sticky top-0 z-50 bg-white">
        {renderNav && (
          <>
            <NavMain pathname={pathname} navigation={navigation} />
            <NavSub pathname={pathname} navigation={navigation} />
          </>
        )}

        {!pathname.includes('/app/') && callout && (
          <div className={`bg-black pl-16 pr-8 xs:pr-16 md:pl-32 ${!callout.show && 'hidden'}`}>
            <Disclosure as="div">
              {({ open }) => (
                <>
                  <Disclosure.Button className="group flex w-full items-center justify-start py-16">
                    <IconAlert className={`mr-8 !text-white ${!callout.showAlertIcon && 'hidden'}`} />

                    <p className="text-h5 text-white">{callout.headline}</p>

                    <IconTriangleDown
                      className={`ml-auto mr-2 fill-current text-white transition duration-150 group-hover:text-white ${
                        open ? '' : 'rotate-90 transform'
                      }`}
                    />
                  </Disclosure.Button>

                  <AnimateHeight open={open}>
                    <Disclosure.Panel static className="ml-32 space-y-16 pb-32">
                      <div className="space-y-16 pb-16">
                        <p className="max-w-screen-xs text-white">{callout.description}</p>
                        {callout.ctaText && callout.destination && (
                          <Link href={callout.destination}>
                            <a className="tracker-button-all-contact_us-20200317-115043 flex items-center space-x-4 text-white hover:underline">
                              <span>{callout.ctaText}</span>

                              <IconArrowRight className="text-white" />
                            </a>
                          </Link>
                        )}
                      </div>
                    </Disclosure.Panel>
                  </AnimateHeight>
                </>
              )}
            </Disclosure>
          </div>
        )}
      </div>
      {children}

      <FooterComponent data={footer} pathname={pathname} />
    </>
  );
};

export default DefaultLayout;
