import Link from 'next/link';

import { Navigation } from '@root/sanity/sanity.types';
import useVisible from '@root/src/hooks/useVisible';
import useLockBodyScroll from '@root/src/hooks/useLockBodyScroll';
import Logo from '@root/src/components/Logo';
import LogoIcon from '@root/src/components/LogoIcon';
import IconX from '@root/src/components/IconX';
import IconMenu from '@root/src/components/IconMenu';
import HelpButton from './HelpButton';
import NavItem, { navItemClassNames, desktopNavItemClassNames } from './NavItem';

type NavMainProps = {
  pathname: string;
  navigation: Navigation;
};

const NavMain = ({ pathname, navigation }: NavMainProps) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  useLockBodyScroll(isVisible);

  const renderNavItems = (isMobile: boolean) =>
    navigation.navigationBuilder
      ?.filter((item) => !item.hidden)
      .map((item) => (
        <NavItem
          isMobile={isMobile}
          item={item}
          key={`${item.title}-${item.href}`}
          pathname={pathname}
          setIsVisible={setIsVisible}
        />
      ));

  return (
    <>
      <header id="header">
        <nav className="flex items-stretch justify-between pl-16 md:pl-32 lg:space-x-32">
          <Link href="/">
            <a className="tracker-nav-main_nav-logo-20200108-150221 flex items-center py-8">
              <Logo className="hidden hover:text-gold-dark xs:block" />

              <LogoIcon className="xs:hidden" />
            </a>
          </Link>

          <div ref={ref} className="nav-main-primary relative z-50 flex flex-grow items-stretch">
            <div
              onClick={() => setIsVisible(!isVisible)}
              className="flex cursor-pointer flex-row items-center px-20 py-16 lg:hidden"
            >
              {isVisible ? (
                <IconX className="h-20 w-20" style={{ color: '#878787' }} />
              ) : (
                <IconMenu className="h-20 w-20" style={{ color: '#878787' }} />
              )}
            </div>

            {/*Mobile NavBar*/}
            <div
              style={{
                top: '105px',
              }}
              className={`${isVisible ? '' : 'hidden'} 
               fixed left-0 bottom-0 overflow-auto bg-white px-20 py-2 shadow-2 lg:hidden`}
            >
              {renderNavItems(true)}
            </div>

            {/*Desktop NavBar*/}
            <div
              className={`absolute top-56 hidden space-y-8 bg-white p-32 shadow-2 lg:relative lg:top-auto lg:flex lg:flex-grow lg:flex-row lg:items-center lg:space-y-0 lg:space-x-32 lg:p-0 lg:shadow-none`}
            >
              {renderNavItems(false)}
            </div>

            <div className="hidden items-stretch xs:flex lg:hidden">
              <Link as="/collection/tuxedos-and-suits" href="/collection/[category]">
                <a
                  className={`${navItemClassNames} ${desktopNavItemClassNames} tracker-nav-main_nav-small_screen_collection-20200108-150221 ${
                    pathname.includes('/collection/') ? 'text-gold-darker' : 'text-gray-darker'
                  }`}
                >
                  Collection
                </a>
              </Link>
            </div>
          </div>

          <div className="flex items-stretch space-x-16 lg:space-x-32 xl:pr-0 lg-only:pr-32">
            <a
              className={`text-h5 ${navItemClassNames} ${desktopNavItemClassNames}`}
              href={`${process.env.NEXT_PUBLIC_APP_URL}/account/login`}
            >
              Login
            </a>

            <a
              className={`text-h5 flex items-center ${navItemClassNames} ${desktopNavItemClassNames}`}
              href={`${process.env.NEXT_PUBLIC_APP_URL}/signup/email`}
            >
              <div className="btn-info btn">Sign Up</div>
            </a>
          </div>

          <HelpButton trackerClass={'tracker-nav-main_nav-help-210730-130721'} />
        </nav>
      </header>
    </>
  );
};

export default NavMain;
