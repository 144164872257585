import React, { ChangeEvent, FormEvent } from 'react';
import { validateEmail } from '../../utils/utils';
import marketingApi from '../../services/marketingApi';
import Message from '../../components/Message';
import FormInput from '../../components/FormInput';

interface Props {
  className: string;
}

interface State {
  email: string;
  success: boolean;
  error: boolean;
}

class MailingListForm extends React.Component<Props, State> {
  static defaultProps: Props;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      success: false,
      error: false,
    };
  }

  handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (!validateEmail(this.state.email)) {
      this.setState(() => ({ error: true }));
      return;
    }

    marketingApi.createLead(this.state.email).then(
      () => this.setState(() => ({ email: '', success: true, error: false })),
      () => this.setState(() => ({ error: true }))
    );
  }

  render() {
    const { className } = this.props;

    return (
      <form onSubmit={(e) => this.handleSubmit(e)} className={`w-full md:w-auto ${className ? className : ''}`}>
        <FormInput
          className="w-full md:w-auto"
          label="Join our Mailing List"
          required
          value={this.state.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value })}
          name="email"
          type="email"
          placeholder="Enter email"
          combineLabel={true}
          errorMessage={this.state.error ? 'Please provide a valid email address.' : undefined}
        />

        {this.state.success && <Message type="success" message="Thanks for subscribing!" />}
      </form>
    );
  }
}

export default MailingListForm;
