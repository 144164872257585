import Link from 'next/link';

import { Link as SanityLink } from '@root/sanity/sanity.types';

type HoverNavProps = {
  navItems: Array<SanityLink>;
};

const HoverNav = ({ navItems }: HoverNavProps) => {
  return (
    <div
      className="
        hover-box
        absolute
        top-80
        hidden
        bg-white
        px-16
        py-8
        shadow-2
        transition-opacity
        duration-200
        ease-linear
        lg:invisible
        lg:block
        lg:origin-top-left
        lg:opacity-0
        lg:group-hover:visible
        lg:group-hover:block
        lg:group-hover:opacity-100
      "
    >
      {navItems.map((item) => {
        return (
          <Link href={item.href} key={item.title}>
            <a
              className={`text-sm block px-8 py-8 text-gray-darker lg:hover:underline ${
                item.trackerClass ? item.trackerClass.replace('sub_nav', 'hover_nav') : ''
              }`}
              {...(item.newTab && { target: '_blank', rel: 'noopener noreferrer' })}
            >
              {item.title}
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default HoverNav;
