import React from 'react';
import { FooterSocialMediaColumn } from '../../../../sanity/sanity.types';
import SocialMediaLink from './SocialMediaLink';

type Props = {
  links: FooterSocialMediaColumn['links'];
};

const SocialMediaGroup = ({ links }: Props) => {
  return (
    <ul className="ml-0 mb-32 flex list-none gap-16">
      {links &&
        links.map((link) => (
          <li key={link._key}>
            <SocialMediaLink {...link} />
          </li>
        ))}
    </ul>
  );
};

export default SocialMediaGroup;
