import FAQSchema from './schemas/faq.json';
import IndexSchema from './schemas/home.json';
import productsApi from 'services/productsApi';
import { Bundle, Item } from 'types';

export const getPageSchema = (pathname: string) => {
  return schemas.find((p) => p.pathname === pathname)?.schema ?? null;
};

export const JsonLdScript = (schema: DynamicObject) =>
  `<script type="application/ld+json">${JSON.stringify(schema)}</script>`;

const schemas = [
  {
    pathname: '/',
    schema: IndexSchema,
  },
  {
    pathname: '/support/faq',
    schema: FAQSchema,
  },
];

export const getItemPageSchema = async (item: Item, url: string) => {
  try {
    const isBundle = (b: any): b is Bundle => ['suit', 'tuxedo'].includes(b.type);

    const itemPageSchema: DynamicObject = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: item.display_name,
      image: `${item.media?.find((m) => m.label?.toLowerCase() === 'pdp_hero')?.url}?fm=jpg&fit=${
        isBundle(item) ? 'crop' : 'max'
      }&w=400&ar=5:7&auto=format`,
      description: item.short_description,
      brand: 'Generation Tux',
      offers: {
        '@type': 'Offer',
        url: `${process.env.NEXT_PUBLIC_LOCAL_URL}${url}`,
        priceCurrency: 'USD',
        price: item.cost,
      },
    };

    if (isBundle(item) && item.productReviews?.reviews.length) {
      itemPageSchema.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: item.productReviews.bottomLine.averageScore.toFixed(2),
        bestRating: (await findBestRating(item.id)).toString(),
        ratingCount: item.productReviews.bottomLine.totalReview.toString(),
      };
    }
    return itemPageSchema;
  } catch (e) {
    throw new Error(`Error retrieving item page schema: ${e}`);
  }
};

const findBestRating = async (id: number, bestRating: number = 0, page: number = 1): Promise<number> => {
  if (bestRating === 5) {
    return bestRating;
  }

  const response = await productsApi.getProductReviews(page, id);

  if (!response?.productReviews?.reviews?.length) {
    return bestRating;
  }

  const { reviews } = response.productReviews;
  let i = 0;

  while (bestRating < 5 && i < reviews.length) {
    bestRating = Math.max(reviews[i].score, bestRating);
    i++;
  }

  return findBestRating(id, bestRating, page + 1);
};
