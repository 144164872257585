import { format, getDaysInMonth, isAfter, isWithinInterval } from 'date-fns';
import { BlockoutDate, Bundle, Item, Product, LastClickCookie, Device, DeviceType } from '../types';
import { fetchSEO } from '../services/seoApi';
import { getBundleBySlug, getProductBySlug } from '../services/productsApi';

const GATE_LIST = ['DESSY', 'ZOLA', 'KNOT', 'WDWIRE'];
const PPC_GATE = ['COMPETITORS', 'NON', 'NON-BRAND'];
/* eslint max-len: ["error", 200], no-useless-escape: 0 */

/**
 * Sorts an array of arrays, each containing elements of the type represented by `T`, by each value
 * belonging to the index given in `field`
 *
 * Example: Given the following `items`, when `field` is `1` and `order` is `desc`:
 *
 * ```javascript
 * [
 *  [1, 2, 3],
 *  [4, 5, 6],
 *  [7, 1, 9],
 * ]
 * ```
 *
 * The following array would be returned:
 *
 * ```javascript
 * [
 *  [4, 5, 6],
 *  [1, 2, 3],
 *  [7, 1, 9],
 * ]
 * ```
 */
const sortBy = <T extends unknown>(items: T[][], field: number, order: 'asc' | 'desc' = 'asc') =>
  items.sort((a: T[], b: T[]) => {
    if (a[field] < b[field]) {
      return order === 'asc' ? -1 : 1;
    } else if (a[field] > b[field]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

/**
 * Returns an array of numbers, starting with `start` and incrementing by `increment` until the length
 * of the array equals `size`
 */
const range = (start: number, increment: number, size: number = 16) => {
  let current = start;
  const range: number[] = [];
  for (let i = 0; i < size; i++) {
    range.push(current);
    current += increment;
  }
  return range;
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const lowerCaseFirstLetter = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

const capitalizeStringAsTitle = (s: string) => {
  return s
    .split(' ')
    .filter((part) => part !== '' && part !== ' ')
    .map((part) => getStringCapitalizedForTitle(part))
    .join(' ');
};

const getUrlAsTitle = (url: string) => {
  const parts = url.split('/');

  const endOfPath = parts.slice(-1)[0];

  return capitalizeStringAsTitle(endOfPath.replace(/-/g, ' '));
};

const getStringCapitalizedForTitle = (word: string) => {
  const lowercasedWords = ['a', 'an', 'and', 'at', 'by', 'but', 'for', 'from', 'of', 'the', 'to'];

  const shouldBeCapitalized = lowercasedWords.every((w) => word !== w);

  if (shouldBeCapitalized) {
    word = capitalizeFirstLetter(word);
  }

  return word;
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const getImageUrl = (product: Product, label: string) => {
  if (product.media && product.media.length > 0) {
    const image = product.media.find((m) => m.label === label);
    if (image) {
      return image.url;
    }
  }
  return 'https://gentux.imgix.net/no-image-avaiable-jsx.png?auto=format&amp;q=70&amp;fit=clip&amp;w=200';
};

interface PredicateFunction {
  (val: string): boolean;
}

/**
 * Returns a filtering function that tests values against a given list of predicates
 *
 * The returned function expects a string, and will return true if:
 *
 *  - Any string predicate is found within the given string
 *  - Any `PredicateFunction` returns true when called with the given string
 */
const combinePredicatesUsingOr = (predicates: Array<string | PredicateFunction>) => {
  return (val: string) =>
    predicates.reduce((id: boolean, predicate: string | PredicateFunction) => {
      if (typeof predicate === 'string') {
        return id || val.toLowerCase().includes(predicate.toLowerCase());
      }
      return id || predicate(val);
    }, false);
};

const getCurrentUrl = () => window.location.origin + window.location.pathname;

const getCollectionUrl = (category: string) => {
  switch (category) {
    case 'Vest':
    case 'Cummerbund':
      return 'vests-and-cummerbunds';
    case 'Shirt':
      return 'shirts';
    case 'Tie':
      return 'ties';
    case 'Suspenders':
    case 'Belt':
    case 'Lapel Pin':
    case 'Tie Bar':
    case 'Cufflinks':
    case 'Pocket Square':
      return 'accessories';
    case 'Shoe':
    case 'Socks':
      return 'shoes-and-socks';
    default:
      return null;
  }
};

const slugifySnake = (text: string) => text.split(' ').join('_').toLowerCase();

const getItemUrl = (item: Item) => {
  if (item.category !== 'preconfigured') {
    return `/collection/${getCollectionUrl(item.category!)}/${item.url_slug}`;
  }

  return `/collection/tuxedos-and-suits/${item.url_slug}`;
};

const formatPhone = (phone: string) => {
  let formattedPhone = phone.replace(/[^0-9]/g, '');

  if (formattedPhone.length > 3) {
    formattedPhone = `(${formattedPhone.substring(0, 3)}) ${formattedPhone.substring(3)}`;
  }
  if (formattedPhone.length >= 6) {
    formattedPhone = formattedPhone.substring(0, 6) + formattedPhone.substring(6);
  }
  if (formattedPhone.length >= 10) {
    formattedPhone = `${formattedPhone.substring(0, 9)}-${formattedPhone.substring(9)}`;
  }

  if (formattedPhone.length > 14) {
    formattedPhone = formattedPhone.substring(0, 14);
  }

  return formattedPhone;
};

const getUrlParams = (): { [key: string]: string } => {
  const vars: { [key: string]: string } = {};

  const hashes = window.location.href
    .replace('#_=_', '')
    .slice(window.location.href.indexOf('?') + 1)
    .split('&');

  for (let i = 0; i < hashes.length; i++) {
    const hash = hashes[i].split('=');
    vars[hash[0]] = hash[1];
  }

  return vars;
};

const getParameterByName = (name: string): string | null => getUrlParams()[name] ?? null;

const isThereFutureBlockOutDate = (blockoutDate: BlockoutDate, relativeDate?: string | Date) => {
  const startDate = blockoutDate.startDate ?? blockoutDate.start_date ?? '';
  const currentDate = relativeDate ?? new Date();

  return isAfter(startDate, currentDate);
};

const isCurrentBlockOutDate = (blockoutDate: BlockoutDate, relativeDate?: string | Date) => {
  const startDate = blockoutDate.startDate ?? blockoutDate.start_date ?? '';
  const endDate = blockoutDate.endDate ?? blockoutDate.end_date ?? '';

  return isWithinInterval(relativeDate ?? new Date(), {
    end: endDate,
    start: startDate,
  });
};

const hasCurrentBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.some((date) => isCurrentBlockOutDate(date, relativeDate));

const getCurrentBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.filter((date) => isCurrentBlockOutDate(date, relativeDate));

const hasFutureBlockOutDates = (blockoutDates: BlockoutDate[], relativeDate?: string | Date) =>
  blockoutDates.some((date) => isThereFutureBlockOutDate(date, relativeDate));

/**
 * Returns an array containing all enumerable values of an object
 *
 * Note: Not to be confused with `Object.entries()`, which returns an array of arrays, each containing
 * the key and value of an enumerable property of the given object
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
 */
const entries = <T extends { [key: string]: any }>(obj: T): T[keyof T][] => Object.keys(obj).map((k) => obj[k]);

/**
 * Returns a multidimensional array where each top level array contains the values of the nth index
 * of each top level array in the given `matrix`
 *
 * In other words, the columns of the provided array will become the rows of the returned array. For
 * example, given the following two dimensional matrix:
 *
 * ```javascript
 * [
 *  [1, 2, 3],
 *  [1, 2, 3],
 *  [1, 2, 3],
 * ]
 * ```
 *
 * `transpose()` will return the following:
 *
 * ```javascript
 * [
 *  [1, 1, 1],
 *  [2, 2, 2],
 *  [3, 3, 3],
 * ]
 * ```
 */
const transpose = <T extends unknown>(matrix: T[][]) =>
  matrix.reduce((acc: T[][], row: T[]) => row.map((_: T, i: number) => (acc[i] || []).concat(row[i])), []);

/**
 * Returns an array that contains values unpacked from a given two-dimensional array
 *
 * For example, given the following input:
 *
 * ```javascript
 * [
 *  [1, 2, 3],
 *  [4, 5, 6],
 * ]
 * ```
 *
 * This will be returned:
 *
 * ```javascript
 * [1, 2, 3, 4, 5, 6]
 * ```
 */
const flatten = <T extends unknown>(arr: T[][]) => arr.reduce((acc: T[], val: T[]) => acc.concat(val), []);

/**
 * Returns a copy of the given array in which only the first occurrence of each unique value is
 * preserved
 *
 * For example, given the following input:
 *
 * ```javascript
 * [2, 1, 2, 3, 2, 1, 4]
 * ```
 *
 * This will be returned:
 *
 * ```javascript
 * [2, 1, 3, 4]
 * ```
 */
const dedupe = <T extends unknown>(arr: T[]) => arr.filter((elem: T, pos: number) => arr.indexOf(elem) === pos);

/** @inheritdoc */
const unique = dedupe;

const isNumber = (value: string) => /^\d+$/.test(value);

const isBrowserEnv = (): boolean => {
  return typeof window !== 'undefined';
};

/**
 * Reads `document.cookie` for a cookie with the given `name` and returns its decoded value if found,
 * otherwise returns `undefined`
 */
const getStringCookie = (name: string): string | undefined => {
  if (!isBrowserEnv()) return undefined;

  const cookie = document.cookie.split('; ').reduce((acc: string, val: string) => {
    const parts = val.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : acc;
  }, '');
  return cookie === '' ? undefined : cookie;
};

/**
 * Reads `document.cookie` for a cookie with the given `name` and returns its JSON decoded value if
 * found, otherwise returns an empty object
 */
const getCookie = <T extends unknown>(name: string): Partial<T> => {
  const cookie = getStringCookie(name) ?? '{}';

  return JSON.parse(cookie);
};

const getCookieDomain = (environment: string) => {
  switch (environment) {
    case 'qa':
    case 'stage':
      return 'gentux.com';
    case 'production':
      return 'generationtux.com';
    default:
      return 'localhost';
  }
};

const getPathFromUrl = (url: string) => url.split(/[?#]/)[0];

/**
 * Returns an array derived from the given `products` that contains only those products whose SKU is
 * found in the given `catalogNumbers`
 *
 * Note - Also now sort it by the catalogNumbers order.
 */
const filterProductsByCatalog = (products: Product[], catalogNumbers: string[]) => {
  const chosenProducts = products.filter((p) => catalogNumbers.indexOf(p.sku) > -1);

  chosenProducts.sort((a: Product, b: Product) => {
    let aIndex = catalogNumbers.indexOf(a.sku);
    let bIndex = catalogNumbers.indexOf(b.sku);
    if (aIndex > bIndex) {
      return 1;
    } else if (aIndex == -1 || bIndex == -1) {
      return 0;
    } else {
      return -1;
    }
  });

  return chosenProducts;
};

const isInList = (value: string | undefined, list: string[]): boolean => {
  return value ? list.some((item) => value.includes(item.toLowerCase())) : false;
};

/**
 * Determines the appropriate path to redirect the user based on their last click information stored in a cookie.
 * If specific conditions related to affiliate or PPC traffic are met, the user is redirected to a gated signup page.
 * Otherwise, the user is sent to the customization page.
 * @returns The URL path to which the user should be redirected.
 */
const renderCustomizeLookPath = (): string => {
  const cookie: LastClickCookie | null = getCookie<LastClickCookie>('last_click_v1');

  if (!cookie) return `${process.env.NEXT_PUBLIC_APP_URL}/customize`;

  const refDet1Lower = cookie.ref_det1?.toLowerCase();
  const refDet2Lower = cookie.ref_det2?.toLowerCase();
  const refSourceLower = cookie.ref_source?.toLowerCase();

  const userCameFromAffiliate = isInList(refDet1Lower, GATE_LIST);
  const userCameFromPPC = refSourceLower === 'ppc' && isInList(refDet2Lower, PPC_GATE);

  const gatedURLSources = ['ads', 'publisher', 'directmail'];
  const shouldRedirectToSignup =
    userCameFromAffiliate || userCameFromPPC || gatedURLSources.includes(refSourceLower ?? '');

  return shouldRedirectToSignup
    ? `${process.env.NEXT_PUBLIC_APP_URL}/gate/signup/email?redirect=/customize`
    : `${process.env.NEXT_PUBLIC_APP_URL}/customize`;
};

const isJacketSku = (sku: string) => sku.charAt(0) === '1';

const getJacketSkuFromSkuArray = (skus: string[]) => skus.filter(isJacketSku)[0];

const getJacketSkuFromSkuList = (skus: string) => {
  const delimiter = skus.indexOf(',') !== -1 ? ',' : '-';
  return skus.split(delimiter).filter(isJacketSku).join();
};

const getLocalURL = () => {
  // in any instance that the app is being build by NextJS
  // we want to point at the local instance of the app
  // to fetch data. Not our currently deployed
  // versions of the app
  if (process.env.BUILD === 'local') {
    return 'http://localhost:3000';
  }

  if (process.env.BUILD === 'ci') {
    return 'http://app:3000';
  }

  return process.env.NEXT_PUBLIC_LOCAL_URL;
};

const formatMonth = (month: number, year: number) => format(new Date(year, month - 1), 'MMM');

const daysInMonth = (year: number, month: number): number => new Date(year, month, 0).getDate();

const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const dayOptions = (year: number, month: number): number[] => {
  return Array.from({ length: daysInMonth(year, month) }, (_, i) => i + 1);
};

const listYears = (firstYear: number, lastYear: number) =>
  [...Array(lastYear + 1 - firstYear).keys()].map((i) => firstYear + i);

const scrollToTop = () =>
  scrollTo({
    top: 0,
    behavior: 'smooth',
  });

interface ObjectLiteral {
  [key: string]: any;
}

const isRegularObject = (subject: unknown): subject is ObjectLiteral => {
  const hasExpectedType = typeof subject === 'object' || typeof subject === 'function';

  if (!hasExpectedType || subject === null) {
    return false;
  }

  return Array.isArray(subject) === false;
};

type FilterValue = {
  displayName: string;
};

type FilterMap = {
  [key: string]: string[] | FilterValue[] | string | number;
};

/**
 * Constructs a query string from a map of filters. Handles both array and single-value filters.
 * For array filters, joins array elements into a comma-separated string.
 * For object arrays, uses 'displayName' property of objects.
 *
 * @param {FilterMap} filtersMap - Object with filter names as keys and filter states as values.
 *                                 Values can be arrays of strings, arrays of objects with 'displayName', or single values.
 *
 * @example
 * // Example input:
 * const filtersMap: FilterMap = {
 *   color: ['red', 'blue'],
 *   type: [{ displayName: 'T-Shirt' }, { displayName: 'Pants' }],
 * };
 * // Returns: "color=red,blue&type=T-Shirt,Pants"
 *
 * @returns {string} Query string representing the filters.
 */
function constructQueryStringFromFilters(filtersMap: FilterMap): string {
  const params = new URLSearchParams();

  Object.keys(filtersMap).forEach((key) => {
    const value = filtersMap[key];
    if (Array.isArray(value)) {
      if (value.length > 0) {
        // Check if the first element of the array is a FilterValue
        const isFilterValueArray = typeof value[0] === 'object' && 'displayName' in value[0];

        const filterValue = isFilterValueArray
          ? (value as FilterValue[]).map((v) => v.displayName).join(',')
          : (value as string[]).join(',');

        params.set(key, filterValue);
      }
    } else if (value) {
      params.set(key, value.toString());
    }
  });

  return params.toString();
}

const hasQueryString = (url: string): boolean => url.indexOf('?') !== -1;

/**
 * Appends the query string from the current page url to the href if the href does not already contain a query string.
 *
 * @param href - The href to which the query string should be appended.
 * @param currentPageUrl - The current page url that may contain a query string.
 * @returns The updated href with the appended query string if applicable, otherwise the original href.
 */
const appendQueryString = (href: string, currentPageUrl: string): string => {
  if (!hasQueryString(href) && hasQueryString(currentPageUrl)) {
    return href + currentPageUrl.slice(currentPageUrl.indexOf('?'));
  }
  return href;
};

const getSwatchImageUrl = (swatch: Product, swatchSize: number): string => {
  const imgixProps = `&w=${swatchSize}&h=${swatchSize}&fit=crop&crop=focalpoint&fp-z=2`;
  return `${process.env.NEXT_PUBLIC_LOOK_BUILDER_ASSETS_BASE_URL}/${
    swatch.sku || swatch.catalogNumber
  }-swatch.png?auto=format${imgixProps}`;
};

/**
 * Generate an string cookie value from object.
 * @param object object to stringify.
 */
const generateCookieStrFromObj = (object: Record<string, string> = {}): string => {
  return Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('; ');
};

export const itemIsBlocked = (item: Item) => {
  const blockoutDates = item.blockoutDates ?? item.blockout_dates ?? [];
  const onHtoPage = window.location.pathname.includes('hto');
  const relativeDate: string | Date = new Date();

  // Disallow selection of HTO blocked items in all forms of HTO item views
  const isHTOAndBlocked = (date: BlockoutDate) =>
    (getParameterByName('htoFlow') !== null || onHtoPage) && date.availability === 'hto';

  return blockoutDates.some((date) => {
    const withinRange = hasCurrentBlockOutDates([date], relativeDate);

    if (date.availability === 'hto') {
      return isHTOAndBlocked(date) && withinRange;
    }

    return withinRange;
  });
};

export const outfitShouldNotDisplay = (item: Item, items: Item[]) =>
  item &&
  items.find((i) => Boolean(i.type) && i.type!.toLowerCase().includes('tux')) !== undefined &&
  item.category!.toLowerCase().includes('belt');

type ItemWithRetailBundle = Item & ({ retailBundle: Bundle } | { retail_bundle: Bundle });

export const itemHasRetailBundle = (item: Item): item is ItemWithRetailBundle => {
  const retailBundle = item.retailBundle ?? item.retail_bundle;

  const isActive = retailBundle?.isActive ?? retailBundle?.is_active ?? false;
  const isDisplayable = retailBundle?.displayable ?? false;

  return !!retailBundle && isActive && isDisplayable;
};

type ItemWithRentalBundle = Item & ({ rentalBundle: Bundle } | { rental_bundle: Bundle });

export const itemHasRentalBundle = (item: Item): item is ItemWithRentalBundle => {
  const rentalBundle = item.rentalBundle ?? item.rental_bundle;

  const isActive = rentalBundle?.isActive ?? rentalBundle?.is_active ?? false;
  const isDisplayable = rentalBundle?.displayable ?? false;

  return !!rentalBundle && isActive && isDisplayable;
};

export const getVariantsOfBundle = (bundle: Bundle) => {
  let rentalBundle: Bundle | undefined;
  let retailBundle: Bundle | undefined;

  if (bundle.isRetail ?? bundle.is_retail) {
    retailBundle = bundle;

    if (itemHasRentalBundle(bundle)) {
      rentalBundle = bundle.rentalBundle ?? bundle.rental_bundle;
    }
  } else {
    rentalBundle = bundle;

    if (itemHasRetailBundle(bundle)) {
      retailBundle = bundle.retailBundle ?? bundle.retail_bundle;
    }
  }

  return { rentalBundle, retailBundle };
};

export const isItemDisabled = (item: Item, productsAndBundle: Item[]) =>
  Boolean(itemIsBlocked(item)) || Boolean(outfitShouldNotDisplay(item, productsAndBundle));

export const formatAsSegmentDate = (date: Date | string) => format(date, 'yyyy-MM-dd HH:mm:ss');

export const getDevice = (navigator: Navigator): Device => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isPhone = /iphone|ipod|android|windows phone/g.test(userAgent);
  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

  if (isPhone) return { type: DeviceType.Phone };

  if (isTablet) return { type: DeviceType.Tablet };

  return { type: DeviceType.Desktop };
};

export const getSEO = async (pathname: string) => {
  try {
    const seo = await (await fetchSEO(pathname)).json();

    if (pathname?.includes('/collection/') && (pathname.match(/\//g) || []).length === 3) {
      if (pathname?.includes('tuxedos-and-suits')) {
        const bundleRes = await getBundleBySlug(pathname.split('/')[3]);

        if (bundleRes.status !== 200 && bundleRes.status !== 201) {
          throw new Error(bundleRes.statusText);
        }

        const bundle = (await bundleRes.json()) as Bundle;

        return seo.title === ''
          ? {
              title: `${bundle.display_name} | Generation Tux`,
              metaDescription: `${bundle.display_name} | ${bundle.short_description}`,
            }
          : seo;
      } else {
        const productRes = await getProductBySlug(pathname.split('/')[3]);

        if (productRes.status !== 200 && productRes.status !== 201) {
          throw new Error(productRes.statusText);
        }

        const product = (await productRes.json()) as Item;

        return seo.title === ''
          ? {
              title: `${product.display_name} | Generation Tux`,
              metaDescription: `${product.display_name} | ${product.short_description}`,
            }
          : seo;
      }
    }

    return seo;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export {
  appendQueryString,
  capitalizeFirstLetter,
  capitalizeStringAsTitle,
  combinePredicatesUsingOr,
  constructQueryStringFromFilters,
  dayOptions,
  daysInMonth,
  dedupe,
  entries,
  filterProductsByCatalog,
  flatten,
  formatMonth,
  formatPhone,
  generateCookieStrFromObj,
  getCollectionUrl,
  getCookie,
  getCookieDomain,
  getCurrentBlockOutDates,
  getCurrentUrl,
  getImageUrl,
  getItemUrl,
  getJacketSkuFromSkuArray,
  getJacketSkuFromSkuList,
  getLocalURL,
  getParameterByName,
  getPathFromUrl,
  getStringCookie,
  getSwatchImageUrl,
  getUrlAsTitle,
  getUrlParams,
  hasCurrentBlockOutDates,
  hasFutureBlockOutDates,
  isBrowserEnv,
  isCurrentBlockOutDate,
  isNumber,
  isRegularObject,
  isThereFutureBlockOutDate,
  listYears,
  lowerCaseFirstLetter,
  monthOptions,
  range,
  renderCustomizeLookPath,
  scrollToTop,
  slugifySnake,
  unique,
  sortBy,
  transpose,
  validateEmail,
};
