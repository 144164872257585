import React, { Fragment } from 'react';
import Link from 'next/link';

import type { FooterSocialMediaColumn, FooterUrlColumn as SanityFooterUrlColumn } from '../../../sanity/sanity.types';
import type { PublishedFooter } from 'types';
import SocialMediaGroup from './footer/SocialMediaGroup';
import FooterUrlGroup from './footer/UrlGroup';
import MailingListForm from '../forms/MailingListForm';
import { cxHours } from '../../data/CxHours';
import LogoIcon from '../../components/LogoIcon';
import Line from '../../components/Line';

type BreadCrumb = {
  title: string;
  path: string;
};

type FooterUrlColumn = SanityFooterUrlColumn & { _key: string };

const makeBreadCrumbs = (pathname: string): BreadCrumb[] => {
  // Converts a slug-like string into a readable format
  const formatTitle = (slug: string): string => {
    return slug
      .toLowerCase()
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
      .split('?')[0];
  };

  // This will be used to accumulate the path for each breadcrumb
  let accumulatedPath = '';

  // Split the pathname and filter out any empty values
  const pathParts = pathname.split('/').filter(Boolean);

  return pathParts.reduce(
    (acc: BreadCrumb[], part) => {
      accumulatedPath += '/' + part;

      const breadcrumb = {
        title: formatTitle(part),
        path: accumulatedPath,
      };

      return [...acc, breadcrumb];
    },
    [
      {
        title: 'Generation Tux',
        path: '/',
      },
    ]
  );
};

type FooterProps = {
  data: PublishedFooter;
  pathname: string;
};

const Footer = ({ data, pathname }: FooterProps) => {
  const breadCrumbs = makeBreadCrumbs(pathname);

  const urlColumns = data?.layout?.filter(({ _type }) => _type === 'footerUrlColumn') as FooterUrlColumn[];

  const socialMediaColumn = data?.layout?.find(
    ({ _type }) => _type === 'footerSocialMediaColumn'
  ) as FooterSocialMediaColumn;

  return (
    <div id="footer" className={`bg-gray-light py-64 ${pathname.includes('collection/') ? 'pb-256 sm:pb-64' : ''}`}>
      <div className="container space-y-32 md:space-y-64">
        <nav className="flex flex-wrap space-x-4 border-b-1 border-gray py-16">
          {breadCrumbs.map((x, i) => (
            <Fragment key={x.title}>
              {i !== 0 && i < breadCrumbs.length && <span>/</span>}

              {i == breadCrumbs.length - 1 ? (
                <span className="text-gray-darker">{x.title}</span>
              ) : (
                <Link href={x.path}>
                  <a className="text-anchor text-gray-darker">{x.title}</a>
                </Link>
              )}
            </Fragment>
          ))}
        </nav>

        <nav className="row gap-y-32">
          <div className="col-span-1 hidden md:block ">
            <LogoIcon />
          </div>

          {urlColumns?.map(({ _key, urlGroups }) => (
            <div key={_key} className="col-span-12 space-y-16 sm:col-span-4 md:col-span-2 lg:space-y-32">
              {urlGroups &&
                urlGroups.map(({ _key, title, links }) => <FooterUrlGroup key={_key} title={title} links={links} />)}
            </div>
          ))}

          <div className="col-span-12 space-y-16 sm:col-span-4 md:col-span-4 lg:space-y-32">
            {socialMediaColumn?.links && (
              <div className="space-y-8">
                <h3 className="text-h5">Follow Us</h3>

                <SocialMediaGroup links={socialMediaColumn.links} />
              </div>
            )}

            <MailingListForm />
          </div>

          <div className="col-span-12 row-start-1 mb-16 w-full space-y-16 bg-white p-32 sm:col-span-12 sm:row-start-auto sm:w-auto sm:self-start md:col-span-3">
            <h3 className="text-h3-display">Help</h3>

            <p className="space-y-4">
              <Link href="/support">
                <a className="text-sm block">Contact Us</a>
              </Link>

              <Link href="/support/faq">
                <a className="text-sm block">Frequently Asked Questions</a>
              </Link>
            </p>

            <Line />

            <p className="space-y-4">
              <a className="text-sm block" href="tel:1-844-726-4889">
                844.726.4889
              </a>

              <Link href="/support/email-us">
                <a className="text-sm block">Email Us</a>
              </Link>
            </p>

            <div className="space-y-4">
              <h3 className="text-h5">Hours (EST)</h3>

              <p className="text-sm">Mon – Fri {cxHours.weekday}</p>
              <p className="text-sm">Sat {cxHours.sat}</p>
              <p className="text-sm">Sun {cxHours.sun}</p>
            </div>
          </div>
        </nav>

        <div className="border-t-1 border-gray pt-16">
          <p className="text-xs text-gray-darker">
            All Text And Images &copy;{new Date().toLocaleDateString('en-US', { year: 'numeric' })} Tux Wholesale
            Services LLC. All Rights Reserved.
          </p>

          <p className="text-xs text-gray-darker">Generation Tux Is Registered Trademark.</p>

          <p className="text-xs text-gray-darker">
            <Link href="/sitemap">
              <a className="block text-gray-darker underline">Sitemap</a>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
