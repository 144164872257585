import React from 'react';
import LinkComponent from 'next/link';

import { Link } from '../../../../sanity/sanity.types';
import { SanityArrayItem } from '@root/src/types';

type Props = {
  links?: SanityArrayItem<Link>[];
  title?: string;
};

const UrlGroup = ({ links, title }: Props) => (
  <div className="space-y-4">
    {title && <h3 className="text-h5">{title}</h3>}

    {links &&
      links.map((link) => (
        <LinkComponent key={link._key} href={link.href}>
          <a
            className="text-anchor text-sm block text-gray-darker"
            {...(link.newTab && { target: '_blank', rel: 'noopener noreferrer' })}
          >
            {link.title}
          </a>
        </LinkComponent>
      ))}
  </div>
);

export default UrlGroup;
