import type { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type LineProps = HTMLAttributes<HTMLHRElement>;

const Line = ({ className = '', ...props }: LineProps) => {
  return <hr {...props} className={twMerge('my-16 w-32 text-gray sm:my-32', className)} />;
};

export default Line;
