import { AppContext } from 'next/app';
import Router from 'next/router';
import { NextPage } from 'next';
import { ComponentType, useEffect } from 'react';

import '@root/lib/bugsnag';
import type { GtUser, SeoData, WebVitalsValue } from 'types';
import { getPageSchema } from 'data/PageSchema';
import { useHydrationStatus } from 'hooks/useHydrationStatus';
import DefaultLayout from 'layout/DefaultLayout';
import { HeadProps } from 'layout/Head';
import AjaxService, { AjaxError } from 'services/ajax';
import { waitUntilGtagHasLoaded } from 'services/google';
import { getClickId, getClickIdFromSession, getClickIdFromUrl, rememberClickId } from 'services/TikTok';
import { sendQueuedOptimizelyEvents } from 'services/optimizely';
import auth from 'utils/auth';
import pixels from 'utils/pixels';
import { getPathFromUrl, getSEO } from 'utils/utils';
import { LoadStatusProvider } from '../src/components/LoadStatus';

import '../styles/globals.css';
import '../styles/yotpo.css';
import '../src/components/look-preview/LookPreview.css';

const ajax = AjaxService();

const identify = () => {
  if (window.gt.user && window.analytics) {
    window.analytics.identify(
      window.gt.user.id,
      {
        firstName: window.gt.user.firstName,
        lastName: window.gt.user.lastName,
        email: window.gt.user.email,
        phone: window.gt.user.phone,
        smsOptIn: window.gt.user.smsOptIn,
        emailOptIn: window.gt.user.emailOptIn,
        state: window.gt.user.state,
        primaryEventId: window.gt.user.primaryEventId || '',
        brand: 'gentux',
      },
      {
        All: true,
        Iterable: {
          phoneNumber: window.gt.user.phone,
        },
        Pinterest: {
          initWithExistingTraits: true,
        },
      } as SegmentAnalytics.SegmentOpts
    );
  }
};

const fetchSession = async () => {
  try {
    return await ajax.post<Promise<GtUser>>('/api/user/session', {
      credentials: 'same-origin',
    });
  } catch (err: unknown) {
    const { status } = err as AjaxError;

    if (status === 401) {
      return null;
    } else {
      throw err;
    }
  }
};

export function reportWebVitals({ id, name, label, value }: WebVitalsValue) {
  if (process.env.ENVIRONMENT === 'production' && window.ga) {
    window.ga('event', name, {
      eventCategory: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
      transport: 'beacon', // Use `sendBeacon()` if the browser supports it.
    });
  }
}

const setClickId = async () => {
  let clickId = getClickIdFromUrl();

  const previousClickId = await getClickIdFromSession();

  if (!clickId && previousClickId) {
    clickId = previousClickId;
  } else if (clickId && previousClickId !== clickId) {
    rememberClickId(clickId);
  }

  window.gt.ttclid = clickId;
};

type Props = HeadProps & {
  pageProps: any;
  Component: NextPageWithLayout;
};

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  layout?: ComponentType<HeadProps>;
};

const shouldIgnoreCanonicalUrl = (path?: string) => {
  return path?.includes('/app') || path?.includes('/blog');
};

/**
 * Configures Google Tag Manager for reporting to our GA4 profile, and then tells
 * Optimizely to release any queued events so they can be tracked in GA4 as well
 */
const configureAnalyticsReporting = async () => {
  try {
    await waitUntilGtagHasLoaded();

    gtag('config', process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID ?? '');
  } catch (e) {
    console.error(e);
  } finally {
    // These should be sent regardless of whether GTM is available,
    // but when it is we'll wait until it's loaded
    sendQueuedOptimizelyEvents();
  }
};

const mountApp = async () => {
  try {
    setClickId();
  } catch (e) {
    console.error(`Encountered an issue setting the user's click ID`, e);
  }

  const user = await fetchSession();

  user && auth.storeUser(user);

  window.organization = 'gentux';

  if (!user) {
    window.localStorage.removeItem('jwt');
  }

  /** Initialize Analytics on server load */
  if (process.env.ENVIRONMENT !== 'production') {
    window.dataLayer = [];
  }

  identify();

  /** Initialize all pixels (TDD) */
  pixels.init();

  /**
   * This will create a callback that runs everytime a new page is loaded via
   * client side routing, i.e. using <Link>
   *
   *
   * !!! SUPER DUPER IMPORTANT NOTE !!!
   * If you're here at some point in the future because you're updating to Next >= 13
   * and want to make use of the `app/`, you'll need to find another solution for the
   * functionality in the event listener below, because Next's updated router for `app/`
   * pages **does not** support `router.events`
   *
   * Refer to the following threads for ideas:
   * - https://github.com/vercel/next.js/discussions/41934
   * - https://github.com/vercel/next.js/discussions/42016
   */
  Router.events.on('routeChangeComplete', () => {
    const ttclid = getClickId();

    window.analytics.page({
      ...(ttclid && { ttclid }),
    });

    identify();

    pixels.page!.loaded();

    window.gt.user = user;

    if (!user) {
      window.localStorage.removeItem('jwt');
    }
  });

  configureAnalyticsReporting();
};

function App(props: Props) {
  useEffect(() => {
    mountApp();
  }, []);

  useHydrationStatus();

  const { Component, pageProps } = props;

  const seo: SeoData = pageProps.seo ?? props.seo ?? {};
  const canonicalUrl: string = pageProps.canonicalUrl ?? props.canonicalUrl ?? '';
  const schema: DynamicObject | null = pageProps.schema ?? props.schema;

  const propsWithSeoData = {
    ...props,
    seo,
    canonicalUrl,
    schema,
  };

  const ChosenLayout = Component.layout ?? DefaultLayout;

  return (
    <ChosenLayout {...propsWithSeoData}>
      <main id="main-content">
        <LoadStatusProvider>
          <Component {...pageProps} />
        </LoadStatusProvider>
      </main>
    </ChosenLayout>
  );
}

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  const pathname = getPathFromUrl(ctx.asPath ?? '/');

  const canonicalUrl = shouldIgnoreCanonicalUrl(pathname) ? null : `https://generationtux.com${pathname}`;

  const seo = await getSEO(pathname);

  const schema = getPageSchema(pathname);

  const props = {
    pathname: ctx.asPath,
    pageProps: {},
    seo,
    canonicalUrl,
    schema,
  };

  if (Component.getInitialProps) {
    props.pageProps = await Component.getInitialProps(ctx);
  }

  return props;
};

export default App;
