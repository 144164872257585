import { DetailedHTMLProps } from 'react';
import {
  BlogAuthor,
  BlogCategory,
  BlogPost,
  Bundle as SanityBundle,
  Picture,
  Product as SanityProduct,
  SanityImageAsset,
  GalleryItem,
  WhoIsWearing,
  Opengraph,
  WhoIsWearingSettings,
  Callout as CalloutEdit,
  Page,
  Navigation as SanityNavigation,
  Footer,
  TextContent,
} from '../sanity/sanity.types';

type UnpackProperty<T> = T extends Array<infer InnerType> ? RecursiveRequired<InnerType>[] : RecursiveRequired<T>;

/**
 * Recursively walks over a given type and makes each optional property required
 */
export type RecursiveRequired<T> = {
  [K in keyof T]-?: UnpackProperty<T[K]>;
};

export type PublishedFooter = RecursiveRequired<Footer>;

export type Callout = Pick<
  CalloutEdit,
  'headline' | 'slug' | 'description' | 'ctaText' | 'destination' | 'showAlertIcon' | 'show'
>;

export interface Breakpoints {
  base?: number | string;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
}

export interface GetWhoIsWearing {
  allWhoIsWearing: WhoIsWearingWithBundle[];
  allWhoIsWearingSettings: WhoIsWearingSettings;
}

export type WhoIsWearingFragment = Pick<WhoIsWearing, '_id' | 'name' | 'description' | 'seo'> & {
  bundle: SanityBundle;
  image: Pick<Picture, 'alt'> & {
    asset: Pick<SanityImageAsset, 'url' | 'metadata'>;
  };
  opengraph?: Pick<Opengraph, 'title' | 'description'> & {
    image?: {
      asset: Pick<SanityImageAsset, 'url' | 'metadata'>;
    };
  };
  _type: 'whoIsWearing';
};

export type WhoIsWearingWithBundle = WhoIsWearingFragment & {
  bundle: Bundle;
};

export type AllWhoIsWearingQuery = {
  allWhoIsWearing: Array<WhoIsWearingFragment>;
  allWhoIsWearingSettings: Array<WhoIsWearingSettings>;
};

export type GalleryItemImage = Pick<Picture, 'alt'> & {
  asset: Pick<SanityImageAsset, 'url' | 'metadata'>;
};

export type GalleryItemFragment = Pick<
  GalleryItem,
  '_id' | 'color' | 'type' | 'category' | 'photoCredit' | 'orderRank' | 'visible'
> & {
  _type: 'galleryItem';
  bundle: SanityBundle;
  image: GalleryItemImage;
};

export type GalleryItemWithBundle = Omit<GalleryItemFragment, 'bundle'> & {
  bundle: Bundle;
};

export type AllGalleryItemQuery = {
  allGalleryItem: Array<GalleryItemFragment>;
};

export type PageSettings = {
  homepage: {
    published: PublishedPage;
    draft?: Page;
  };
  _type: 'pageSettings';
};

export type PublishedPage = Pick<Page, 'orderRank'> & Required<Omit<Page, 'orderRank'>>;

export type PublishedNavigation = Pick<SanityNavigation, 'orderRank'> & Required<Omit<SanityNavigation, 'orderRank'>>;

export type BlogAllQuery = {
  allBlogPost: Array<BlogPostFragment>;
};

/**
 * Sanity blog related to getting all categories
 */
export type BlogAllCategory = {
  allBlogCategory: BlogCategory[];
};

export type BlogIndexQuery = BlogAllQuery & {
  allBlogSettings: [
    {
      title: string;
    }
  ];
};

export type FeaturedProduct = SanityBundle | SanityProduct;

export type BlogPostFragment = Pick<BlogPost, '_id' | 'slug' | 'title' | 'publishedAt' | 'seo'> & {
  mainImage: Pick<Picture, 'alt' | 'link'> & {
    asset: Required<Pick<SanityImageAsset, 'url' | 'metadata'>>;
  };
  bodyRaw: object[];
  excerpt: string;
  firstBlockText: string;
  featuredProducts: FeaturedProduct[];
  opengraph?: Pick<Opengraph, 'title' | 'description'> & {
    image?: {
      asset: Pick<SanityImageAsset, 'url' | 'metadata'>;
    };
  };
  blogCategories?: BlogCategory[];
  author: BlogAuthor;
};

export type BlogAuthorFragment = Pick<BlogAuthor, '_id' | 'slug' | 'name' | 'shortBio'> & {
  picture: Pick<Picture, 'alt' | 'link'> & {
    asset: Pick<SanityImageAsset, 'url' | 'metadata'>;
  };
  longBioRaw: TextContent;
};

export type BlogAllAuthorsQuery = {
  allBlogAuthor: Array<BlogAuthor>;
};

export interface BlockoutDate {
  id?: number;
  blockable_type?: string;
  availability?: string;
  blockable_id?: number;
  start_date?: string;
  end_date?: string;
  startDate?: string;
  endDate?: string;
  created_at?: string;
  updated_at?: string;
}

export interface ItemProperties {
  attributePrimary?: string;
  attributeSecondary?: string;
  attributeTertiary?: string;
  available_in_boys?: boolean;
  available_in_slim?: boolean;
  blockout_dates?: BlockoutDate[];
  blockoutDates?: BlockoutDate[];
  catalogNumber?: string | null;
  category?: string;
  color?: string;
  cost?: string;
  details?: Array<ProductDetail | BundleDetail>;
  display_index?: number | string | null;
  display_name?: string;
  displayable?: boolean;
  displayName?: string;
  id: number;
  is_active?: boolean;
  is_retail?: boolean;
  isActive?: boolean;
  isRetail?: boolean;
  media?: Array<ProductMedia | BundleMedia>;
  name?: string;
  pattern?: string | null;
  rental_bundle?: Bundle;
  rental_terms?: string | null;
  rentalBundle?: Bundle;
  rentalTerms?: string | null;
  retail_bundle?: Bundle;
  retailBundle?: Bundle;
  short_description?: string | null;
  shortDescription?: string | null;
  sku: string;
  sub_category?: string;
  swatch?: Product | null;
  type?: string;
  url_slug: string;
  urlSlug?: string;
}

export interface Product extends ItemProperties {
  pivot?: ProductBundlePivot;
}

export interface Bundle extends ItemProperties {
  products?: Array<Product>;
  looks?: Array<Bundle>;
  productReviews?: {
    reviews: ProductReview[];
    bottomLine: BottomLine;
  };
}

/**
 * Sanity All Bundle
 **/
export type SanityBundleAll = {
  allBundle: Array<SanityBundle>;
};

/**
 * Sanity All Bundle
 **/
export type SanityProductAll = {
  allProduct: Array<SanityProduct>;
};

export interface RetailBundleReference {
  id: number | null;
}

export type Item = Product | Bundle;

export type Visualizer = Item & {
  skus: string[];
};

export interface ProductBundlePivot {
  product_id?: number;
  bundle_id?: number;
}

export interface ProductDetail {
  id: number;
  product_id: number;
  detail?: string;
  created_at: string;
  updated_at: string;
}

export interface BundleDetail {
  id: number;
  bundle_id: number;
  detail?: string;
  created_at: string;
  updated_at: string;
}

export interface Media {
  label?: string;
  url?: string;
  display_name?: string;
  description?: string | null;
  display_index?: number;
  created_at?: string;
  modified_at?: string | null;
  created_by?: number;
  modified_by?: number | null;
}

export interface ProductMedia extends Media {
  product_media_id?: number;
  product_id?: number;
}

export interface BundleMedia extends Media {
  bundle_media_id?: number;
  bundle_id?: number;
}

export interface Filter {
  categories: ProductCategoryFilter[];
  colors: string[];
  patterns: string[];
  procurement: string[];
  tieTypes: string[];
}

export interface Category {
  displayName: string;
  name: string;
  slug: string;
  filters: string[];
}

export interface ProductCategoryFilter {
  displayName: string;
  value: string | ProductCategoryPredicate;
}

export interface ProductCategoryPredicate {
  (category: string): boolean;
}

export interface ProductReview {
  id: number;
  score: number;
  title: string;
  content: string;
  reviewer: string;
  createdAt: number;
  customFields?: ProductReviewMetaData[] | null;
}

export interface NormalizedProductReview {
  data: ProductReview[];
  totalReviewCount: number;
  averageStarRating: number;
  page: number;
}

export interface ProductReviewMetaData {
  title: string;
  value: number;
}

export interface BottomLine {
  averageScore: number;
  totalReview: number;
}

export interface ProductReviewPagination {
  page: number;
  perPage: number;
  total: number;
}

/**
 * Represents the purchase type of products that a promo code can be applied to
 */
export type DiscountContract = 'rental' | 'purchase';

export type DiscountType = 'dollar' | 'percent';

/**
 * Represents a promo code from the API, as it appears in the database
 */
export type PromoCodeEntry = {
  id: string;
  code: string;
  description: string;
  active: 0 | 1;
  discount_type: DiscountType;
  discount_restriction_contract: DiscountContract | null;
  organization_id: number;

  /**
   * A datetime string representing when this promo code becomes active
   */
  start_date: string | null;

  /**
   * A datetime string representing when this promo code becomes inactive
   */
  end_date: string | null;

  /**
   * The amount of dollars or percentage points that this discount applies
   *
   * This value is always formatted with a precision of two decimal places, so 25%
   * or $25 would be represented as `'25.00'`
   */
  discount_amount: string;

  /**
   * A semicolon delimited list of user roles that the promo applies to
   */
  discount_restriction_roles: string | null;

  /**
   * A semicolon delimited list of product categories that the promo applies to
   */
  discount_restriction_categories: string | null;
};

/**
 * Represents a promo code with normalized values
 *
 * Compared to the response we get from the API, these objects:
 *
 *  - Use camelCase instead of snake_case for the property names
 *  - Use booleans instead of `0 | 1` for boolean values
 *  - Make all constant string values (e.g. "rental") lowercase
 *  - Transform the restriction lists from strings into arrays of strings (or an empty array if null)
 */
export type PromoCode = {
  id: string;
  code: string;
  description: string;
  active: boolean;
  startDate: string | null;
  endDate: string | null;
  discountType: DiscountType;
  discountAmount: string;
  discountRestrictionRoles: string[];
  discountRestrictionCategories: string[];
  discountRestrictionContract: DiscountContract | null;
};

export interface GtEvent {
  id?: string;
  startDate?: string;
  name?: string;
  eventType?: string;
  type?: string;
  role?: string;
  year?: number;
  day?: number;
  month?: number;
  accountId?: string;
  isTrial?: boolean;
  members?: Member[];
  roles?: Look[];
  status?: string;
  lastCampaign?: LastCampaign;
  metadata?: string;
  source?: string;
  potentialMembers?: PotentialMember[];
  partyRoles?: PartyRole[];
  shortCode?: ShortCode;
  gtEventType?: GtEventType;
  gtuxId?: number;
  clonedEvent?: GtEvent;
  partySize?: number;
  usedDefaultEventDate?: boolean;
}

export interface GtEventType {
  id?: number;
  name?: string;
}

export interface GtEventCreated extends GtEvent {
  partyRoleId?: number;
  partySize?: number;
}

export interface GtUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  smsOptIn: boolean;
  sms_opt_in?: boolean;
  primaryEventId: string;
  primary_event_id?: string;
  jwt?: string;
  first_name?: string;
  last_name?: string;
  emailOptIn?: boolean;
  email_opt_in?: boolean;
  state?: string;
  isCx?: boolean;
  is_cx?: boolean;
}

export interface PartyRole {
  id: number;
  name: string;
  isRenting: boolean;
  gtEventType: GtEventType;
}

export interface PotentialMember {
  id?: string;
  nickname?: string;
  partyRole?: PartyRole;
  role?: Look;
  member?: Member | null;
  key?: string | number;
}

export interface Member {
  id?: string;
  paying?: boolean;
  look?: string;
  isOwner?: boolean;
  isCreator?: boolean;
  isInvited?: boolean;
  isMeasured?: boolean;
  isPaid?: boolean;
  isReceived?: boolean;
  isReserved?: boolean;
  isReturned?: boolean;
  isShipped?: boolean;
  trackingCode?: string;
  accountId?: string;
  event?: GtEvent;
  role?: Look;
  customer?: Customer;
  orders?: Order[];
  gtEvent?: GtEvent;
  potentialMember?: PotentialMember | null;
  productToMember?: ProductToMember[];
}

export interface ProductToMember {
  isPaidFor?: boolean;
  isDeleted?: boolean;
  product: Product;
}

export interface Customer {
  id?: string;
  password?: string;
  email?: string;
  phone?: string;
  firstName?: string;
  first_name?: string;
  lastName?: string;
  last_name?: string;
  addresses?: Array<{ id: string }>;
  isRegistrationComplete?: boolean;
  primaryEventId?: string;
  primary_event_id?: string;
  primaryEvent?: GtEvent;
  primary_event?: GtEvent;
  organizationId?: number;
  organization_id?: number;
  jwt?: string;
  organization?: Organization;
  smsOptIn?: boolean;
  sms_opt_in?: boolean;
  emailOptIn?: boolean;
  email_opt_in?: boolean;
  state?: string;
  isCx?: boolean;
  is_cx?: boolean;
}

export type Look = {
  bundleIds: number[];
  id: string;
  name?: string;
  productIds: number[];
  share_link_code: string;
  image_url: string;
};

export type LookPreview = {
  bundles?: Bundle[];
  id: string;
  name?: string;
  products?: Product[];
  share_link_code: string;
  image_url: string;
};

export type LookPreviewItem = {
  activeItem: Item | null;
  category: string;
  icon: string;
  id: number;
  isBundle: boolean;
  placeholder: string;
};

export interface Organization {
  id: number;
}

export interface Order {
  status?: string;
  isTrial?: number;
  shipment?: Shipment;
  id?: string;
  gtuxId?: number;
  subtotal?: number;
  isSwatch?: boolean | number;
  tax?: number;
  total?: number;
  fees?: Fee[];
  address?: Address;
  needsByDate?: string;
  member?: Member;
  createdAt?: string;
}

export interface Fee {
  id?: number;
  type?: string;
  amount?: number;
  taxRate?: number;
}

export interface Shipment {
  id?: number;
  cartons?: Carton[];
  status?: string;
}

export interface Address {
  id?: number;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  isDefault: boolean;
  streetAddress?: string;
}

export interface Carton {
  type: string;
  trackingUrl: string;
  labelUrl?: string;
  isSwatch?: boolean;
}

export interface CampaignAttributions {
  key: string;
  value: string;
}

export interface LastCampaign {
  attributions?: CampaignAttributions[];
}

export interface ShortCode {
  id: string;
  code: string;
  gtEvent?: GtEvent;
}

interface TTDUniversalPixelApi {
  init: (key: string, array: string[], url: string) => void;
}

export interface WindowEvent {
  id: string;
  name: string;
  gtuxId: number;
  isTrial: number;
  status: string;
  startDate: string;
  type: string;
  isOwner: number;
  memberId: string;
}

export interface JsonResponse<T> extends Response {
  json(): Promise<T>;
}

export interface GraphQLPayload<T = {}> {
  data?: T;
  errors?: GraphQLError[];
}

/**
 * @todo There are other keys (like the stack trace on the server side)
 *  but I don't think we really need them right now since IIRC they aren't
 *  referenced anywhere in our codebase yet, so we'll add them when needed
 */
export interface GraphQLError {
  message: string;
}

export interface SuitSection {
  subTitle: string;
  title: string;
  body: string;
  bodyLink: {
    url: string;
    text: string;
  };
  backgroundImg: {
    url: string;
    alt: string;
  };
  polaroidImg: {
    url: string;
    alt: string;
  };
  ctaUrl: string;
  bundle: Bundle[];
  products: Item[];
}

export interface SeoData {
  title: string;
  metaDescription: string;
  slug: string;
}

export interface Url {
  title: string;
  slug: string;
  as?: string;
}

export interface Navigation {
  collection: Url[];
  gallery: Url[];
  how_it_works: Url[];
  our_story: Url[];
  support: Url[];
}

export interface MainNavItem {
  title: string;
  as?: string;
  href: string;
  trackerClass: string;
  activeStr?: string;
  exclude?: string[];
  hoverNav?: Url[];
}

export interface WebVitalsValue {
  id: string;
  name: string;
  label: string;
  value: number;
}

export interface Gtag {
  (command: 'config', target: string, params?: { [key: string]: unknown }): void;
  (command: 'get', target: string, fieldName: string, callback: (field?: string) => void): void;
  (command: 'set', parameters: { [key: string]: unknown }): void;
  (command: 'event', eventName: string, params?: { [key: string]: unknown }): void;
}

/**
 * An object describing an Optimizely experiment that a user belongs to
 *
 * @see https://docs.developers.optimizely.com/web-experimentation/reference/state
 */
export type Experiment = {
  id: string;

  /**
   * An object describing the variation that the user was bucketed into for this
   * experiment, or `null` if they weren't bucketed
   */
  variation: null | ExperimentVariation;
};

/**
 * Maps an experiment ID to an object describing the state of the experiment for
 * the current user
 */
export type ExperimentByID = {
  [key: string]: Experiment;
};

export type ExperimentVariation = {
  id: string;
};

/**
 * An object containing several functions that return information about things like
 * what experiments are active, the current user and their buckets, etc.
 *
 * @see https://docs.developers.optimizely.com/web-experimentation/reference/state
 */
export type OptimizelyState = {
  getActiveExperimentIds(): string[];
  getExperimentStates(filter?: { isActive: boolean }): ExperimentByID;
};

export type CampaignDecidedEvent = {
  data: {
    decision: {
      experimentId: string;
      variationId: string;
    };
  };
};

export type OnCampaignDecided = (event: CampaignDecidedEvent) => any;

/**
 * Optimizely's JavaScript snippet for the Web Experimentation API
 *
 * @see https://docs.developers.optimizely.com/web-experimentation/docs/getting-started
 */
export type OptimizelySnippet = {
  initialized: boolean;
  get(argument: 'state'): OptimizelyState;
  push(options: OptimizelyPushOptions): any;
};

type OptimizelyPushOptions =
  | OptimizelyAddCampaignDecidedListener
  | OptimizelyUserAttributesTracking
  | OptimizelyHoldOrSendEvents;

type OptimizelyAddCampaignDecidedListener = {
  type: 'addListener';
  filter: {
    type: 'lifecycle';
    name: 'campaignDecided';
  };
  handler: OnCampaignDecided;
};

type OptimizelyUserAttributesTracking = {
  type: 'user';
  attributes: {
    [key: string]: string;
  };
};

type OptimizelyHoldOrSendEvents = {
  type: 'holdEvents' | 'sendEvents';
};

/**
 * Type for `last_click_v1` cookie
 */
export type LastClickCookie = {
  ref_det1?: string;
  ref_det2?: string;
  ref_source?: string;
};

declare global {
  var gt: {
    user: GtUser | null;
    ttclid?: string | null;
  };

  var gtag: Gtag;

  interface Window {
    gt: {
      user: GtUser | null;
      env: string;
      orgId: number;
      events?: WindowEvent[];
      lookBuilderAssetsBaseUrl: string;
      ttclid?: string | null;
    };

    trackers: {
      unbounceconvert: () => any;
    };

    organization: string;
    STRIPE_PUBLIC_KEY: string;
    analytics: SegmentAnalytics.AnalyticsJS;
    optimizely: OptimizelySnippet;
    ttd_dom_ready: (callback: () => void) => void;
    TTDUniversalPixelApi: new () => TTDUniversalPixelApi;
    _ubaq: string[];
    dataLayer: unknown[];
    ga: Gtag;
    yotpo: {
      initWidgets: () => void;
    };
    Bugsnag?: typeof import('@bugsnag/js').default;
  }

  namespace JSX {
    interface IntrinsicElements {
      strike: DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export enum DeviceType {
  Phone = 'phone',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export type Device = {
  type: DeviceType;
};

export type SanityLayoutComponentSpacing = {
  spaceBottom?: 'collapsed' | '';
  spaceTop?: 'collapsed' | '';
};

/** Ensures all array items include `_key` */
export type SanityArrayItem<T> = T & { _key: string };

export interface SanityReference<T> {
  _type: 'reference';
  _ref: string;
  _weak?: boolean;
  document?: T;
}
