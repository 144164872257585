import React from 'react';
import FormContainer from './FormContainer';
import IconPencil from './IconPencil';
import { formBaseClassNames, formBaseClassNamesDarkMode, hasCombinedLabel } from './FormUtils';

interface FormInputProps extends React.HTMLProps<HTMLLabelElement & HTMLInputElement> {
  combineLabel?: Boolean;
  darkMode?: Boolean;
  inputAddOn?: String;
  errorMessage?: String;
  disabledMessage?: String;
  description?: string;
  hideIcon?: Boolean;
  inputClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLLabelElement & HTMLInputElement>) => void;
}

type Props = React.FC<FormInputProps>;

const FormInput: Props = ({
  placeholder = 'Enter something...',
  inputMode = 'text',
  pattern,
  hideIcon,
  inputClassName = '',
  ...props
}) => {
  var {
    combineLabel,
    label,
    inputAddOn,
    errorMessage,
    disabledMessage,
    darkMode,
    className,
    description,
    ...cleanedProps
  } = props;

  const combinedLabel = hasCombinedLabel(props);

  return (
    <FormContainer
      className={className}
      label={label}
      disabled={props.disabled}
      darkMode={darkMode}
      inputAddOn={inputAddOn}
      errorMessage={errorMessage}
      disabledMessage={disabledMessage}
      description={description}
      hasCombinedLabel={combinedLabel}
      formIcon={!hideIcon && <IconPencil className={props.disabled ? 'text-gray' : 'text-black'} />}
    >
      <input
        placeholder={placeholder}
        {...cleanedProps}
        className={`
          ${formBaseClassNames} 
          ${combinedLabel ? 'pt-40' : ''} 
          ${darkMode ? formBaseClassNamesDarkMode : ''}
          ${hideIcon ? 'pr-16' : 'pr-40'}
          ${inputClassName}
        `}
        inputMode={inputMode}
        {...(pattern && { pattern: pattern })}
      />
    </FormContainer>
  );
};

export default FormInput;
