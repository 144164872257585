import React from 'react';

interface MessageProps {
  message?: string;
  type?: 'error' | 'success' | 'info' | 'default';
}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & MessageProps>;

const Message: Props = ({ className, children, message, type = 'default', ...props }) => {
  let typeClass: string;

  switch (type) {
    case 'default':
      typeClass = 'bg-gray text-gray-darker';
      break;
    case 'info':
      typeClass = 'bg-gold text-gray-darker';
      break;
    case 'success':
      typeClass = 'bg-green text-white';
      break;
    case 'error':
      typeClass = 'bg-red text-white';
      break;
  }

  return (
    <div {...props} className={`mb-8 p-16 shadow-1 ${typeClass} ${className ? className : ''}`}>
      {message && <p className={`text-sm ${typeClass}`}>{message}</p>}

      {children}
    </div>
  );
};

export default Message;
