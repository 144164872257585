import React from 'react';

interface IconLinkedinProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconLinkedinProps>;

const IconLinkedin: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Linkedin logo</title>
      <path
        className="st0"
        d="M14.8,0H1.2C0.5,0,0,0.5,0,1.2v13.7C0,15.5,0.5,16,1.2,16h13.6c0.7,0,1.2-0.5,1.2-1.2V1.2C16,0.5,15.5,0,14.8,0
                      z M4.7,13.6H2.4V6h2.4V13.6z M3.6,5C2.8,5,2.2,4.3,2.2,3.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C4.9,4.3,4.3,5,3.6,5z
                      M13.6,13.6h-2.4V9.9c0-0.9,0-2-1.2-2c-1.2,0-1.4,1-1.4,2v3.8H6.2V6h2.3v1h0c0.3-0.6,1.1-1.2,2.2-1.2c2.4,0,2.8,1.6,2.8,3.6V13.6z"
      />
    </svg>
  );
};

export default IconLinkedin;
