import Link from 'next/link';
import IconQuestionMark from '../../components/IconQuestionMark';

interface HelpButtonProps {
  trackerClass: string;
}

const HelpButton = ({ trackerClass }: HelpButtonProps) => {
  return (
    <Link href="/support">
      <a
        title="Help Page"
        className={`${trackerClass} ml-16 flex items-center bg-gray-light px-8 text-black no-underline transition hover:bg-white xs:ml-32 xs:px-16 lg-only:hidden`}
      >
        <div className="relative grid h-[20px] w-[20px] place-items-center rounded-[50%] border border-black">
          <IconQuestionMark />
        </div>
      </a>
    </Link>
  );
};

export default HelpButton;
