import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getPathFromUrl as getPathWithoutQueryOrHash } from 'utils/utils';

/**
 * Sets an attribute on the body tag that marketing can target via Optimizely,
 * in order to delay its modification of the DOM until after Next has hydrated
 * the page
 */
export const useHydrationStatus = () => {
  const router = useRouter();

  useEffect(() => {
    const path = getPathAsAttributeValue(router.asPath);

    document.body.setAttribute('data-hydrated-page', path);
  }, [router.asPath]);
};

/**
 * A pattern to match the domain name for either of the static sites
 */
export const IS_SITE_URL_REGEX = /gen(?:eration)?tux\.com/i;

/**
 * Matches the absolute path from a URL for either of the static sites
 */
export const MATCH_PATH_FROM_URL_REGEX = /(?:qa\.)?gen(?:eration)?tux\.com(.*)/i;

/**
 * Returns a boolean indicating whether the site was loaded within Optimizely's
 * WYSIWYG proxy
 *
 * @see https://github.com/generationtux/shared-ecomm-js/blob/master/documentation/optimizely-proxy.md
 */
export const isOptimizelyProxyPath = (path: string) => IS_SITE_URL_REGEX.test(decodeURIComponent(path));

/**
 * Returns the *actual* path, relative to our site, from the given URL that React
 * router returns whenever the site has been loaded in Optimizely's proxy
 *
 * @see https://github.com/generationtux/shared-ecomm-js/blob/master/documentation/optimizely-proxy.md
 */
export const getActualPathFromUrl = (url: string) => {
  const path = MATCH_PATH_FROM_URL_REGEX.exec(decodeURIComponent(url))?.[1] ?? null;

  if (path === null) {
    throw new Error(`Failed to extract a path from the given URL: ${url}`);
  }

  if (path === '') {
    return '/';
  }

  return path;
};

/**
 * Returns a transformed version of the pathname for use as a data attribute
 *
 * @example
 * ```
 * // Returns: 'ab_cd'
 * getPathAsAttributeValue('/ab/cd/?test=x&y=z#123');
 * ```
 */
export const getPathAsAttributeValue = (path: string) => {
  if (isOptimizelyProxyPath(path)) {
    path = getActualPathFromUrl(path);
  }

  path = getPathWithoutQueryOrHash(path);

  if (path === '/') {
    return 'home';
  }

  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }

  return path.toLowerCase().replace(/[\/\s]+/g, '_');
};
