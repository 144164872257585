import Bugsnag from '@bugsnag/js';
import getConfig from 'next/config';
const config = getConfig();

Bugsnag.start({
  apiKey: config
    ? config.serverRuntimeConfig.BUGSNAG_API_KEY || config.publicRuntimeConfig.BUGSNAG_API_KEY
    : process.env.BUGSNAG_CLIENT_API_KEY,
  releaseStage: config.publicRuntimeConfig.ENVIRONMENT,
  enabledReleaseStages: ['qa', 'stage', 'production'],
  ...(config.publicRuntimeConfig.ENVIRONMENT === 'local' && { logger: null }),
});
