import AjaxService from 'services/ajax';

const ajax = AjaxService();

const marketingApi = {
  createLead(email: string) {
    return ajax.post<'OK'>('/api/create-lead', {
      body: { email },
    });
  },
};

export default marketingApi;
