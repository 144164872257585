import { experiments, ExperimentToBuckets } from '../static/experiments';
import { ExperimentByID, OnCampaignDecided, OptimizelySnippet } from '../types';

/**
 * Returns a boolean indicating whether Optimizely's JS snippet is loaded and fully available
 */
export const optimizelyHasLoaded = () => window.optimizely?.initialized === true;

export class OptimizelyUnavailableError extends Error {
  readonly name = 'OptimizelyUnavailableError';

  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, OptimizelyUnavailableError.prototype);
  }
}

/**
 * Returns `window.optimizely`, and will make up to five attempts to call itself
 * again if the snippet hasn't fully loaded and/or initialized yet
 */
export const getOptimizelyObject = (attempt = 1) => {
  return new Promise<OptimizelySnippet>((resolve, reject) => {
    if (typeof window === 'undefined') {
      return reject(new OptimizelyUnavailableError());
    }

    if (optimizelyHasLoaded()) {
      return resolve(window.optimizely);
    }

    if (attempt > 5) {
      return reject(new OptimizelyUnavailableError());
    }

    setTimeout(() => {
      getOptimizelyObject(attempt + 1).then(resolve, reject);
    }, 500);
  });
};

export const getExperiments = async () => {
  try {
    const optimizely = await getOptimizelyObject();

    const state = optimizely.get('state');

    return state.getExperimentStates();
  } catch (e) {
    if (e instanceof OptimizelyUnavailableError) {
      return null;
    }

    throw e;
  }
};

/**
 * Adds a listener to Optimizely's `campaignDecided` event, which is fired any
 * time a user is sorted into a variation (bucket) of an experiment
 */
export const onUserBucketed = async (handler: OnCampaignDecided) => {
  const optimizely = await getOptimizelyObject();

  optimizely.push({
    handler,
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name: 'campaignDecided',
    },
  });
};

/**
 * Triggers Optimizely's `sendEvents`, which tells the snippet to publish any
 * events that were enqueued before the snippet had loaded and initialized
 */
export const sendQueuedOptimizelyEvents = async () => {
  try {
    const optimizely = await getOptimizelyObject();

    optimizely.push({ type: 'sendEvents' });
  } catch (e) {
    console.error(
      "Couldn't trigger sendEvents: Optimizely snippet wasn't loaded within the expected amount of time (~2.5s)"
    );
  }
};

/**
 * Returns an object representing the experiments a user belongs to, in a way that's
 * readable throughout the codebase
 */
export const getUserBuckets = (data: ExperimentByID) => {
  const states = Object.values(data);
  const entries = Object.entries(experiments);

  return entries.reduce((experimentToBucket, entry) => {
    const [name, experiment] = entry;

    const state = states.find(({ id }) => experiment.id === id);

    // Return early if the experiment isn't currently running
    if (!state) {
      return experimentToBucket;
    }

    const knownVariations = Object.entries(experiment.variations);

    const variation = knownVariations.find((variation) => {
      const [, variationId] = variation;

      return state.variation?.id === variationId;
    });

    if (!variation) {
      return experimentToBucket;
    }

    const [bucket] = variation;

    return {
      ...experimentToBucket,
      [name]: bucket,
    };
  }, {} as Partial<ExperimentToBuckets>);
};

/**
 * Returns a boolean indicating whether the app has been loaded on optimizelyedit.com
 */
export const siteIsLoadedInOptimizelyProxy = () => /(www\.)?optimizelyedit\.com/i.test(window.location.hostname);
