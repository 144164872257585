import { HTMLAttributes } from 'react';

type IconTikTokProps = HTMLAttributes<HTMLOrSVGElement>;

const IconTikTok = ({ className, ...props }: IconTikTokProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      version="1.1"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>TikTok logo</title>
      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
    </svg>
  );
};

export default IconTikTok;
