import { createContext, useContext, useState, useCallback, PropsWithChildren } from 'react';

export type LoadStatusMap = { [url: string]: boolean };

export type LoadStatusCallbacks = {
  hasLoaded: (url: string) => boolean;
  setHasLoaded: (url: string) => void;
};

type LoadStatusProviderProps = PropsWithChildren<{
  sources?: LoadStatusMap;
}>;

const LoadStatusContext = createContext<LoadStatusCallbacks>({
  hasLoaded: (_) => false,
  setHasLoaded: (_) => {},
});

export const useLoadStatus = () => useContext(LoadStatusContext);

export const LoadStatusProvider = ({ children, sources = {} }: LoadStatusProviderProps) => {
  const [statusMap, setStatusMap] = useState<LoadStatusMap>(sources);

  const hasLoaded = useCallback(
    (url: string) => {
      return statusMap[url] ?? false;
    },
    [statusMap]
  );

  const setHasLoaded = useCallback(
    (url: string) => {
      setStatusMap((currentStatusMap) => ({
        ...currentStatusMap,
        [url]: true,
      }));
    },
    [statusMap]
  );

  return <LoadStatusContext.Provider value={{ hasLoaded, setHasLoaded }}>{children}</LoadStatusContext.Provider>;
};
