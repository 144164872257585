import React, { ReactNode } from 'react';
import IconAlert from './IconAlert';
import IconLock from './IconLock';

interface FormContainerProps {
  formIcon?: ReactNode;
  label?: string;
  darkMode?: Boolean;
  inputAddOn?: String;
  errorMessage?: String;
  disabledMessage?: String;
  description?: string;
  useFieldset?: Boolean;
  hasCombinedLabel: Boolean;
}

type Props = React.FC<React.HTMLProps<HTMLLabelElement> & FormContainerProps>;

const FormContainer: Props = ({
  label,
  errorMessage,
  disabledMessage,
  formIcon,
  className,
  children,
  description,
  useFieldset = false,
  darkMode = false,
  disabled,
  hasCombinedLabel,
}) => {
  const headerClassNames = () =>
    `
    text-h4
    pointer-events-none normal-case 
    ${disabled ? 'text-gray-dark' : ''} 
    ${description ? 'relative pb-0' : ''} 
    ${hasCombinedLabel ? 'relative z-10 h-0 px-16 transform translate-y-16' : 'p-16'}
    ${useFieldset ? 'block w-full box-border' : ''}
  `;

  return (
    <label
      className={`relative m-0 inline-block border-0 p-0 ${description ? 'has-description' : ''} ${
        darkMode ? 'form-darkmode' : ''
      } ${className ? className : ''}`}
    >
      <div className={`${darkMode ? 'bg-white' : 'bg-gray-lighter'}`}>
        {label &&
          (useFieldset ? (
            <legend className={`${headerClassNames()}`}>{label}</legend>
          ) : (
            <div className={`${headerClassNames()}`}>{label}</div>
          ))}

        {description && (
          <div>
            <p className="max-w-md px-16 pt-0 pb-16 text-sm text-gray-dark">{description}</p>
          </div>
        )}
      </div>

      <div className="relative flex">
        <div className="flex-grow">{children}</div>
        <div
          className={`pointer-events-none absolute right-16 inline-flex -translate-y-1/2 transform items-center text-right`}
          style={{
            top: hasCombinedLabel ? 'calc((100% - 24px) * 0.5 + 24px)' : '50%',
          }}
        >
          {formIcon && formIcon}
        </div>
      </div>

      <div>
        {errorMessage && (
          <div className="flex items-center bg-red px-16 py-8">
            <div className="flex items-center space-x-4">
              <IconAlert className="text-white" />

              <p className="text-sm text-white">{errorMessage}</p>
            </div>
          </div>
        )}

        {disabled && disabledMessage && (
          <div className="flex items-center bg-gray-light px-16 py-8">
            <div className="flex items-center space-x-4">
              <IconLock />

              <p className="text-sm text-black">{disabledMessage}</p>
            </div>
          </div>
        )}
      </div>
    </label>
  );
};

export default FormContainer;
