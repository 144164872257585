import type { JsonResponse } from 'types';

export const getClickIdFromUrl = () => {
  const params = new URLSearchParams(window.location.search);

  return params.get('ttclid');
};

export const getClickId = () => getClickIdFromUrl() ?? window.gt.ttclid;

export const rememberClickId = async (clickId: string): Promise<boolean> => {
  const response = await fetch(`/api/tik-tok/click-id`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      Accept: `application/json`,
    },
    body: JSON.stringify({ clickId }),
  });

  if (response.status >= 400) {
    throw new Error(`(HTTP ${response.status}) Failed to set TikTok click ID: ${await response.text()}`);
  }

  const body = await response.json();

  return body.clickId === clickId;
};

export const getClickIdFromSession = async (): Promise<string | null> => {
  const response: JsonResponse<{ clickId: string | null }> = await fetch(`/api/tik-tok/click-id`);

  if (response.status >= 400) {
    throw new Error(`(HTTP ${response.status}) Failed to get TikTok click ID: ${await response.text()}`);
  }

  const { clickId } = await response.json();

  return clickId;
};

export const withTikTokClickId = (data: { [key: string]: any }) => {
  const ttclid = getClickId();

  return {
    ...data,
    ...(ttclid && { ttclid }),
  };
};
