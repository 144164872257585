const hasCombinedLabel = (props) => !!props.combineLabel && !props.description && !!props.label;

let formBaseClassNames = `
text-base
leading-6
appearance-none
bg-none
rounded-none
border-gray
border
bg-white
m-0
w-full
box-border
transition
p-16
outline-none
placeholder-gray-dark
hover:border-gold-darker
hover:bg-gold-lighter
focus:bg-white
focus:border-gold
focus:shadow-form-focus
disabled:cursor-not-allowed
disabled:bg-gray-lighter
disabled:border-gray
disabled:shadow-none
disabled:placeholder-gray
`;

let formBaseClassNamesDarkMode = ``;

export { formBaseClassNames, formBaseClassNamesDarkMode, hasCombinedLabel };
