import Link from 'next/link';
import useVisible from '../../hooks/useVisible';
import { Navigation, SubNav } from '@root/sanity/sanity.types';
import { useEffect, useState } from 'react';
import { isActiveNavLink } from '@root/src/partials/nav/NavItem';

type NavItemsProps = {
  navSection: SubNav;
  pathname: string;
  setIsVisible: (bool: boolean) => void;
} & ClassNameProp;

const NavItems = ({ navSection, pathname, className = '', setIsVisible }: NavItemsProps) => (
  <nav className={`bg-gray-lighter ${className} `}>
    {navSection.subNavSectionBuilder?.map((item) => (
      <div key={item.title}>
        <Link href={`${item.href}`}>
          <a
            onClick={() => setIsVisible(false)}
            className={`text-sm hover:underline ${pathname === item.href ? 'text-gold-darker' : 'text-gray-darker'} ${
              item.trackerClass ?? ''
            }`}
            {...(item.newTab && { target: '_blank', rel: 'noopener noreferrer' })}
          >
            {item.title}
          </a>
        </Link>
      </div>
    ))}
  </nav>
);

type NavSubProps = {
  navigation: Navigation;
  pathname: string;
} & ClassNameProp;

const NavSub = ({ navigation, pathname, className = '', ...props }: NavSubProps) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const [navSection, setNavSection] = useState<SubNav>();

  useEffect(() => {
    const activeNavSection = navigation?.navigationBuilder?.find(({ href }) => isActiveNavLink(pathname, href));
    setNavSection(activeNavSection);
  }, [pathname]);

  if (!navSection?.subNavSectionBuilder?.length) return null;

  return (
    <div ref={ref} {...props} className={className}>
      <NavItems
        navSection={navSection}
        pathname={pathname}
        setIsVisible={setIsVisible}
        className="hidden space-x-16 p-16 lg:flex lg:pl-[164px]"
      />
    </div>
  );
};

export default NavSub;
