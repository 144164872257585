import Link from 'next/link';
import { useState } from 'react';
import { SubNav } from '@root/sanity/sanity.types';
import IconTriangleDown from '@root/src/components/IconTriangleDown';
import AnimateHeight from '@root/src/components/AnimateHeight';
import HoverNav from '@root/src/partials/nav/HoverNav';

type NavItemProps = {
  item: SubNav;
  pathname: string;
  setIsVisible: (isVisible: boolean) => void;
  isMobile?: boolean;
} & ClassNameProp;

export const navItemClassNames = `
  relative
  flex
  items-center
  justify-between
  whitespace-nowrap
`;
export const desktopNavItemClassNames = `
  after:absolute
  after:left-0
  after:bottom-0
  after:h-1
  after:w-full
  after:bg-black
  after:transform
  after:scale-x-0
  after:origin-bottom-left
  after:transition-all
  after:duration-200
  after:ease-linear
  hover:after:scale-x-100
  focus:after:scale-x-100
  hover:text-gold-darker
  focus:text-gold-darker
  hover:no-underline
`;

export type NavItemType = 'Main' | 'Sub';

export const isActiveNavLink = (pathname: string, href: string, navItemType: NavItemType = 'Main') => {
  const urlPathSegments = pathname.split('/').filter(Boolean);
  const hrefPathSegments = href.split('/').filter(Boolean);

  const [mainUrlPathSegment, subUrlPathSegment] = urlPathSegments;
  const [mainNavItemSegment, subNavItemSegment] = hrefPathSegments;

  return navItemType === 'Sub'
    ? urlSegmentsMatch(subNavItemSegment ?? '', subUrlPathSegment ?? '')
    : urlSegmentsMatch(mainNavItemSegment ?? '', mainUrlPathSegment ?? '');
};

const urlSegmentsMatch = (a: string, b: string) => {
  if (!a || !b) {
    return false;
  }

  return a === b;
};

const NavItem = ({
  item: { title, href, trackerClass, subNavSectionBuilder },
  isMobile,
  pathname,
  setIsVisible,
}: NavItemProps) => {
  const [expand, setExpand] = useState<boolean>(false);

  const toggleExpand = () => {
    setExpand((prev) => !prev);
    setIsVisible(false);
  };

  return (
    <div className={`group pointer-events-auto py-12 lg:py-0 ${isMobile ? 'border-b-1 border-gray' : ''}`}>
      {subNavSectionBuilder?.length && isMobile ? (
        <div
          onClick={() => setExpand((prev) => !prev)}
          className={`lg:h-80 ${!isMobile ? desktopNavItemClassNames : ''} ${navItemClassNames} ${
            isActiveNavLink(pathname, href) ? 'text-gold-darker' : 'text-gray-darker'
          } ${trackerClass ?? ''}`}
        >
          <span>{title}</span>
          <IconTriangleDown className={`lg:hidden ${expand ? '' : 'rotate-90'}`} />
        </div>
      ) : (
        <Link href={href}>
          <a
            onClick={() => setIsVisible(false)}
            className={`lg:h-80 ${!isMobile ? desktopNavItemClassNames : ''} ${navItemClassNames} ${
              isActiveNavLink(pathname, href) ? 'text-gold-darker after:scale-x-100' : 'text-gray-darker'
            } ${trackerClass ?? ''}`}
          >
            <span>{title}</span>
          </a>
        </Link>
      )}

      <AnimateHeight open={expand} className={'lg:hidden'}>
        <div className={'mt-[10px] mb-2 ml-[10px] flex flex-col space-y-[10px]'}>
          {subNavSectionBuilder?.map((subNavItem) => (
            <div key={`${subNavItem.title}-${subNavItem.href}`}>
              <Link href={subNavItem.href}>
                <a
                  onClick={toggleExpand}
                  className={`text-sm flex md:hover:underline ${
                    isActiveNavLink(pathname, subNavItem.href, 'Sub') ? 'text-gold-darker' : 'text-gray-darker'
                  } ${subNavItem.trackerClass ?? ''}`}
                  {...(subNavItem.newTab && { target: '_blank', rel: 'noopener noreferrer' })}
                >
                  {subNavItem.title}
                </a>
              </Link>
            </div>
          ))}
        </div>
      </AnimateHeight>
      {subNavSectionBuilder && <HoverNav navItems={subNavSectionBuilder} />}
    </div>
  );
};

export default NavItem;
