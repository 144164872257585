import IconFacebook from 'components/IconFacebook';
import IconInstagram from 'components/IconInstagram';
import IconPinterest from 'components/IconPinterest';
import IconTwitter from 'components/IconTwitter';
import IconTikTok from 'components/IconTikTok';
import IconLinkedin from 'components/IconLinkedin';
import IconYouTube from 'components/IconYouTube';

type SocialMediaSite = 'facebook' | 'pinterest' | 'twitter' | 'instagram' | 'tiktok' | 'linkedin' | 'youtube';

type Props = {
  site: SocialMediaSite;
  url: string;
};

type IconProps = {
  site: SocialMediaSite;
  className: string;
};

const siteToIcon = {
  facebook: IconFacebook,
  instagram: IconInstagram,
  pinterest: IconPinterest,
  twitter: IconTwitter,
  tiktok: IconTikTok,
  linkedin: IconLinkedin,
  youtube: IconYouTube,
};

const SocialMediaIcon = ({ site, ...props }: IconProps) => {
  const Icon = siteToIcon[site as keyof typeof siteToIcon];

  return <Icon {...props} />;
};

const getAttributesBySiteName = (site: SocialMediaSite): DynamicObject => {
  switch (site) {
    case 'pinterest':
      return {
        'data-pin-log': 'button_follow',
      };
    case 'twitter':
      return {
        'data-show-count': 'false',
        'data-size': 'large',
        'data-show-screen-name': 'false',
        'data-dnt': 'true',
      };
    case 'facebook':
      return {
        'data-href': 'https://www.facebook.com/generationtux',
        'data-layout': 'standard',
        'data-action': 'like',
        'data-show-faces': 'true',
        'data-share': 'true',
      };
    default:
      return {};
  }
};

const SocialMediaLink = ({ site, url }: Props) => {
  const dataAttributes = getAttributesBySiteName(site);

  return (
    <a href={url} id={`follow-${site}`} target="_blank" {...dataAttributes}>
      <SocialMediaIcon site={site} className="!text-blue-dark hover:!text-black" />
    </a>
  );
};

export default SocialMediaLink;
