import Head from 'next/head';

import { JsonLdScript } from 'data/PageSchema';
import { SeoData } from '../types';

export type HeadProps = {
  seo: SeoData;
  pathname: string;
  canonicalUrl: string | null;
  schema: DynamicObject | null;
};

const renderCanonicalMetaTag = ({
  canonicalUrl,
  pathname,
}: {
  canonicalUrl: HeadProps['canonicalUrl'];
  pathname: HeadProps['pathname'];
}) => {
  if (canonicalUrl && canonicalUrl !== '') {
    return <link rel="canonical" href={canonicalUrl} />;
  } else if (!canonicalUrl && pathname !== '/') {
    return <link rel="canonical" href={`https://generationtux.com${pathname}`} />;
  }
};

const Header = ({ canonicalUrl, pathname, seo, schema }: HeadProps) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" key="charSet" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content={seo.metaDescription} />
        <meta name="viewport" key="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="google-site-verification" content="0qn8MZFBFJPl40wa9BrpuE8TxLf58eSlqbmo4rew-Sw" />
        <title>{seo.title}</title>
        <link rel="preconnect" href="http://cdn.segment.com" />
        {/* establish early connections to third-party origins to avoid round-trips */}
        <link rel="dns-prefetch" href="//gentux.imgix.net" />

        {renderCanonicalMetaTag({ canonicalUrl, pathname })}

        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/touch-icon.png" />
        <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap" />
      </Head>

      {/* Note: This uses dangerouslySetInnerHTML, because otherwise */}
      {/* the inner content will end up in a malformed state, due to */}
      {/* some escaping that NextJS does during the build process.   */}
      {schema && (
        <div
          dangerouslySetInnerHTML={{
            __html: JsonLdScript(schema),
          }}
        />
      )}

      <div id="fb-root" />
    </>
  );
};

export default Header;
